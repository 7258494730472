<template>
    <v-card flat class="rounded-lg">
        <v-toolbar flat class="rounded-t-lg" height="56">
            <v-menu ref="menu" v-model="menu_mes" :close-on-content-click="false" bottom right origin="top left" transition="scroll-x-transition" rounded="lg">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon class="mr-2 ml-0 toolbar-menu-btn" :class="TemaDark?'primary':'secondary'" v-bind="attrs" v-on="on">
                        <v-icon :color="TemaDark?'white':'primary'" size="20" class="icon_btn_picker">mdi-calendar</v-icon>
                    </v-btn>
                </template>
                <v-card  class="rounded-lg" flat>
                    <v-date-picker v-model="fecha_calendario" type="month" scrollable flat :show-current="true" no-title width="280" :min="rango.inicio" :max="CalendarioMaxLimite"  @change="GetData(turno_id, fecha_calendario)">
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" >Hoy</v-btn>
                        <v-btn text color="primary" @click="menu_mes = false">Cerrar</v-btn>
                    </v-date-picker>
                </v-card>
            </v-menu>
            <v-toolbar-title class="pa-0 text-capitalize" style="line-height: 1.1;">
                <v-list-item class="px-0">
                    <v-list-item-content class="py-0">
                        <v-list-item-title class="headline mb-0">
                            <v-toolbar-title class="pa-0 text-capitalize trenos-title-toolbar" style="line-height: 1.1;" :class="TemaDark?'grey--text text--lighten-3':'grey--text text--darken-1'">{{CalendarioTitulo.mes}}</v-toolbar-title>
                        </v-list-item-title>
                        <v-list-item-subtitle class="font-weight-medium mb-0">
                            <span class="text-capitalize">{{CalendarioTitulo.año}}</span>
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <div v-if="CalendarioAnterior" class="pr-1">
                <v-btn icon color="primary" @click="GetData(turno_id, FechaCambio(1, 0))" >
                    <v-icon size="20">mdi-chevron-left</v-icon>
                </v-btn>
            </div>
            <div v-else>
                <v-btn icon disabled>
                    <v-icon size="20">mdi-close</v-icon>
                </v-btn>    
            </div>
            <div v-if="CalendarioSiguiente" @click="GetData(turno_id, FechaCambio(0, 1))">
                <v-btn icon color="primary" >
                    <v-icon size="20">mdi-chevron-right</v-icon>
                </v-btn>
            </div>
            <div v-else>
                <v-btn icon disabled>
                    <v-icon size="20">mdi-close</v-icon>
                </v-btn>
            </div>
        </v-toolbar>
        <v-card flat v-if="lista.length" max-height="60vh" min-height="38vh" style="overflow: auto;">
            <v-list class="rounded-lg py-1" v-for="maquinista in lista" :key="maquinista.fecha" dense min-height="50">
                <v-list-item flat  class="rounded-lg pr-3 pl-5">
                    <v-list-item-avatar class="my-1 mr-2" size="30">
                        <v-avatar :color="TemaDark?'grey darken-3':'grey lighten-4'" size="30">
                            <v-icon size="15" :color="TemaDark?'grey lighten-4 ':'grey'">mdi-account-circle-outline</v-icon>
                        </v-avatar>
                    </v-list-item-avatar>
                    <v-list-item-content class="py-0">
                        <v-list-item-title class="text-subtitle-2 text-capitalize mb-0" :class="TemaDark?'grey--text text--lighten-3':'grey--text text--darken-1'" v-for="(nombre, index) in maquinista.nombre" :key="index">
                            {{nombre}}
                        </v-list-item-title>
                        <v-list-item-subtitle class="text-capitalize">
                            <v-icon size="13" class="mr-0">mdi-calendar</v-icon>
                            {{FormatFecha(maquinista.fecha).nombre}} {{FormatFecha(maquinista.fecha).numero}}
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-card>
        <v-card flat height="40vh" class="justify-center align-center d-flex flex-column" v-else>
            <v-icon size="50" color="grey lighten-1">
                mdi-calendar-remove-outline
            </v-icon>
            <span class="trenos-title-toolbar grey--text text--lighten-1">Sin asignaciones registradas</span>
        </v-card>
    </v-card>
</template>

<script>
import { mapState} from 'vuex'
import dayjs from "dayjs"
export default {
    props: ['turno_id','rango'],
    name: 'TurnoAsignaciones',
    data() {
        return {
            menu_mes: false,
            fecha: dayjs().format('YYYY-MM-DD'),
            fecha_calendario : dayjs().format('YYYY-MM-DD'),
            lista: []
        }
    },
    computed:{
        ...mapState(['usuario', 'dialog', 'sistema', 'update']),
        TemaDark(){
            let dark = false
            if(this.sistema.tema == 'dark' || (this.sistema.tema == 'system' && window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches)) dark = true
            return dark
        },
        CalendarioTitulo() {
            let calendario = {
                mes: dayjs(this.fecha).format('MMMM'),
                año: dayjs(this.fecha).format('YYYY')
            }
            return calendario
        },
        CalendarioAnterior(){
            let anterior = false
            if(this.rango.inicio){
                if(dayjs(this.rango.inicio).date(1).format('YYYY-MM-DD') < this.fecha)
                anterior =  true
            }
            return anterior
        },
        CalendarioSiguiente(){
            let siguiente = false
            if(this.rango.termino){
                if(dayjs(this.rango.termino).date(1).format('YYYY-MM-DD') > this.fecha) siguiente =  true
                
            }else{
                if(dayjs().date(1).add(1,'month').format('YYYY-MM-DD') > this.fecha) siguiente =  true
            }
            return siguiente
        },
        CalendarioMaxLimite(){
            let fecha_limite
            if(this.rango.termino == null) fecha_limite = dayjs().date(1).add(1,'month').format('YYYY-MM-DD')
            return fecha_limite
        }
    },
    methods:{
        async GetData(turno_id, fecha) {
            if(turno_id){
                let fecha_url = dayjs(fecha).format('YYYYMM')
                let url = `/buscar/turno/${turno_id}/calendario/${fecha_url}`
                await this.axios.get(url, { headers: { token: this.usuario.token }
                }).then(respuesta => {
                    if (respuesta.data.mensaje) {
                        this.ErrorRequest(respuesta.data.mensaje)
                    } else {
                        this.lista = respuesta.data.lista
                        this.fecha = fecha
                        this.fecha_calendario = fecha
                        this.menu_mes = false
                    }
                })
            }
            
        },
        FormatFecha(fecha) {
            let dia = {
                nombre: dayjs(fecha).format('dddd').replace(".", ""),
                numero: dayjs(fecha).format('DD')
            }
            return dia
        },
        FechaCambio(ant, sig){
            let fecha = this.fecha
            if(ant) fecha = dayjs(fecha).date(1).subtract(1, 'month').format('YYYY-MM-DD')
            if(sig) fecha = dayjs(fecha).date(1).add(1, 'month').format('YYYY-MM-DD')
            return fecha
        }
    },
    mounted(){
        this.GetData(this.turno_id, dayjs().format('YYYY-MM-DD') )
    },
    watch: {
        turno_id: function (val) {
            if (val) this.GetData(this.turno_id, dayjs().format('YYYY-MM-DD') )
        },
    }
}
</script>

<style>

</style>